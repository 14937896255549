.page-checkout-with-invoice {

    .page-content-wrapper {
        position: relative;
        max-width: 70rem;
        padding-bottom: 10rem;
        padding-top: 8rem;
        transition: padding-top 0.5s;
    }

    &.back-action-hidden {
        .page-content-wrapper {
            padding-top: 4rem;
        }
    }

    .back-action {
        position: absolute;
        top: 4rem;
    }

    .checkout-actions-wrapper {
        position: absolute;
        display: flex;
        justify-content: space-between;
        bottom: 0;
        left: 2rem;
        right: 2rem;
        padding: 2rem 0;
        border-top: 1px solid #cadceb;

        .cancel-action {
            text-align: left;
        }

        .resend-invoice-action-wrapper {
            text-align: right;
            width: 65%;
            font-weight: bold;

            .link {
                color: #0055b8;
                font-weight: bold;
            }
        }
    }

    h1 {
        margin-bottom: 2rem;
    }

    .message {
        width: 100%;
        padding-bottom: 2rem;
        font-size: 1.1rem;
        line-height: 1.4;

        b {
            font-weight: bold;
        }
    }
}

@media screen and (max-width: 75rem) {
    .page-checkout-with-invoice  {
        .page-content-wrapper {
            padding-top: 4rem;
        }

        &.back-action-hidden {
            .page-content-wrapper {
                padding-top: 2rem;
            }
        }

        .back-action {
            top: 2rem;
        }
    }
}

@media screen and (max-width: 40rem) {
    .page-checkout-with-invoice  {
        .page-content-wrapper {
            padding-bottom: 15rem;
        }

        .checkout-actions-wrapper {
            flex-direction: column-reverse;

            .resend-invoice-action-wrapper {
                padding-bottom: 1rem;
                text-align: left;
            }
        }
    }
}
