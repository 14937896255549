.form-sale-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 2rem;
    font-size: 1rem;

    select {
        border-color: #e5f5fc;
    }

    .customer-details {
        padding-bottom: 1rem;
        border-bottom: 1px solid #cadceb;
        text-align: right;
        line-height: 1.4;

        span {
            display: block;
            text-align: left;
        }

        .title {
            padding-top: 1rem;
        }

        .details-change-action {
            color: #0055b8;
            font-size: 0.9rem;
            text-decoration: none;
        }
    }

    .order-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
    }

    .installation-date {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        padding-top: 0.5rem;

        .calendar-wrapper {
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
            padding: 1rem;
            border: 2px solid #e5f5fc;
            border-radius: 2rem;
            background-color: #fff;
            text-align: center;
        }

        .schedule-error-message {
            width: 100%;
            margin-top: 2rem;
            padding-left: 4rem;
            background-image: url('../../../../assets/images/icons/sad.svg');
            background-repeat: no-repeat;
            background-size: 3rem;
            background-position: center left;
            color: #888;
            min-height: 3rem;
            line-height: 1.3rem;
            display: flex;
            align-items: center;
        }

        .calendar-loading-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5rem;
            width: 100%;
        }
    }
}

@media screen and (max-width: 75rem) {
    .form-sale-wrapper {
        position: static;

        .customer-details,
        .order-date,
        .installation-date,
        .installation-time {
            max-width: 30rem;
        }
    }
}

@media screen and (max-width: 40rem) {
    .form-sale-wrapper {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
