.page-cart {
    .title-wrapper {
        display: flex;
        align-items: baseline;

        h1 {
            margin-right: 2rem;

            + span {
                color: #3377c6;
                font-size: 1rem;
                line-height: 1.4;
            }
        }

        b {
            font-weight: 500;
        }

        .truck-icon {
            display: inline-block;
            padding-left: 1.75rem;
            margin-left: 0.5rem;
            background-image: url('../../assets/images/icons/truck.svg');
            background-size: 1.5rem;
            background-position: center left;
            background-repeat: no-repeat;
        }
    }

    .cart-actions-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
        padding: 2rem 0;
    }
}

@media screen and (max-width: 75rem) {
    .page-cart {
        .title-wrapper {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 50rem) {
    .page-cart {
        .cart-actions-wrapper {
            flex-direction: column-reverse;
            align-items: center;
            margin-top: 0;

            .cancel-action {
                margin-top: 3rem;
            }
        }
    }
}
