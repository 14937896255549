.tech-assist-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .form-action-wrapper {
        display: flex;
        justify-content: space-between;

        .customer-info {
            text-align: left;
            color: #888;
        }
    }

    .coverage-error-message {
        display: none;
        width: 100%;
        margin-top: 2rem;
        padding-left: 4rem;
        background-image: url("../../../../assets/images/icons/sad.svg");
        background-repeat: no-repeat;
        background-size: 3rem;
        background-position: center left;
        color: #888;
    }

    &.area-not-covered {
        .coverage-error-message {
            display: block;
        }
    }
}

@media screen and (max-width: 50rem) {
    .tech-assist-form {
        .coverage-error-message {
            padding-top: 4rem;
            padding-left: 0;
            background-position: top left;
        }

        &.area-not-covered {
            .form-action-wrapper {
                display: none;
            }
        }
    }
}
