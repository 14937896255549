.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    min-height: calc(100vh - 7rem);
    margin-top: 7rem;
    padding: 2rem 60% 2rem 2rem;
    color: #221f20;
    overflow: hidden;

    &::before {
        position: absolute;
        left: 35%;
        right: 0;
        bottom: 0;
        top: 0;
        background-size: cover;
        background-image: url('../../assets/images/hero.png');
        background-position: center left;
        background-repeat: no-repeat;
        content: '';
        z-index: -1;
    }

    &::after {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1rem;
        background-color: #221f20;
        content: '';
    }

    .logo {
        position: relative;
        display: block;
        width: 100%;
        max-width: 30rem;
        z-index: 5;
    }

    h2 {
        padding-bottom: 3vh;
        color: #0055b8;
        font-size: 1.8rem;
        line-height: 1.3;

        b {
            display: block;
            font-size: 1.1em;
            font-weight: bold;
        }
    }

    p {
        padding-bottom: 5vh;
        line-height: 1.6;
        font-size: 1.4rem;

        i {
            color: #35a399;
            font-size: 1.3rem;
            font-weight: bold;
            font-style: italic;
            text-transform: uppercase;
        }
    }
}


@media screen and (max-width: 75rem) {
    .hero {
        margin-top: 5rem;
        padding-right: 2rem;

        &::before {
            display: none;
        }
    }
}

@media screen and (max-width: 40rem) {
    .hero {
        margin-top: 4rem;
        padding-right: 1rem;
        padding-left: 1rem;

        &::before {
            display: none;
        }

        h2 {
            b {
                display: inline;
            }
        }
    }
}
