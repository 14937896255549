.modal-wrapper {
    position: fixed;
    top: 7rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 100;


    .modal {
        position: absolute;
        background-color: #fff;
        color: #221f20;
        border-radius: 1rem;
        overflow: hidden;
    }

    &.size-s {
        .modal {
            left: 0;
            right: 0;
            max-width: 50rem;
            width: 90%;
            max-height: 90vh;
            margin: 5vh auto 0;
        }
    }

    &.size-ss {
        .modal {
            left: 0;
            right: 0;
            max-width: 40rem;
            width: 90%;
            max-height: 90vh;
            margin: 5vh auto 0;
        }
    }

    &.size-l {
        top: 0;
        z-index: 120;

        .modal {
            position: absolute;
            max-width: 150rem;
            max-height: unset;
            left: 1rem;
            right: 1rem;
            bottom: 1rem;
            top: 1rem;
            margin: auto;
        }
    }

    &.two-column {
        .scroll-wrapper {
            padding: 0;
        }
    }

    .scroll-wrapper {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 3rem;
        word-wrap: break-word;
    }

    h1 {
        font-size: 2.5rem;
        margin-bottom: 0.75em;
    }

    p {
        line-height: 1.6;
        padding-bottom: 1rem;
    }

    .link {
        color: #0055b8;
    }

    .action-wrapper {
        text-align: right;

        button:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .modal-close-action {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;
        width: 2rem;
        height: 2rem;
        padding: 0;
        background-color: transparent;
        background-image: url('../../assets/images/icons/cross.svg');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 100;
    }

    .cancel-action {
        color: #a82510;
        font-size: 1rem;
        text-decoration: none;
    }

    .error-message-icon {
        width: 100%;
        margin-top: 2rem;
        padding-left: 4rem;
        background-image: url("../../assets/images/icons/sad.svg");
        background-repeat: no-repeat;
        background-size: 3rem;
        background-position: center left;
        color: #888;
        min-height: 3rem;
    }
}

@media screen and (max-width: 75rem) {
    .modal-wrapper {
        &.size-l,
        &.size-s {
            top: 5rem;

            .modal {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                max-width: unset;
                height: calc(100vh - 5rem);
                max-height: unset;
                border-radius: 0;
                margin: 0;
            }
        }

        .scroll-wrapper {
            padding: 2rem;
        }

        h1 {
            font-size: 2rem;
        }

        .modal-close-action {
            right: 2rem;
            background-color: #fff;
        }
    }
}

@media screen and (max-width: 40rem) {
    .modal-wrapper {
        &.size-l,
        &.size-s {
            top: 4rem;

            .modal {
                height: calc(100vh - 4rem);
            }
        }

        .scroll-wrapper {
            padding-left: 1rem;
        }

        .modal-close-action {
            right: 1rem;
        }

        h1 {
            padding-right: 2rem;
        }
    }
}
