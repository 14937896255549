.preloader-wrapper {
    &.inline {
        position: relative;
        display: inline-block;
        width: 3rem;
        height: 3px;
        border-radius: 3px;
        background-color: #bededa;
        overflow: hidden;
        z-index: 1;

        &::before,
        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 3rem;
            height: 3px;
            border-radius: 3px;
            border-left: 2px solid #bededa;
            border-right: 2px solid #bededa;
            content: '';
            animation: lineMovement 2s infinite linear;
            transform: translate(-100%);
            box-sizing: border-box;
        }

        &::before {
            background-color: #1e9a8b;
        }

        &::after {
            background-color: #52baad;
            animation-delay: 1s;
            animation-duration: 1.5s;
        }

        .loding-circles {
            display: none;
        }
    }

    &.overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(232, 239, 239, 0.75);
        z-index: 100;

        &.transparent {
            background-color: transparent;
        }

        span {
            padding: 1rem;
            font-size: 0.85rem;
            font-weight: 400;
            text-shadow: 0 0 0.5rem #e8efef;
        }

        svg {
            display: block;
            width: 5rem;
            height: 5rem;

            .outer,
            .inner {
                fill: transparent;
                stroke-dasharray: 10;
                stroke-dashoffset: 0;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-width: 0.35;
                animation: circleStrokeRotate 2s infinite linear;
            }

            .outer {
                stroke: #1e9a8b;
            }

            .inner {
                stroke: #52baad;
            }

            .check {
                fill: #fff;
                opacity: 0;
            }
        }

        &.finished {

            svg {
                animation: loadingFinished 1s forwards;

                .outer {
                    animation: circleStrokeFinised 0.8s forwards;
                }

                .inner {
                    animation: circleStrokeFinised 0.8s forwards, circleFillFinised 0.8s forwards;
                }

                .check {
                    animation: fadeIn 0.8s forwards;
                }
            }
        }
    }
}


@keyframes circleStrokeRotate {
    from {stroke-dashoffset: 0;}
    to {stroke-dashoffset: 20;}
}

@keyframes circleStrokeFinised {
    from {stroke-dasharray: 10; stroke-dashoffset: 20;}
    to {stroke-dasharray: 30; stroke-dashoffset: 0;}
}

@keyframes circleFillFinised {
    from {fill: transparent;}
    to {fill: rgba(30, 154, 139, 0.5);}
}

@keyframes loadingFinished {
    0% {transform: scale(1);}
    80% {transform: scale(1.2);}
    100% {transform: scale(1);}
}

@keyframes lineMovement {
    0% {transform: translate(-100%);}
    70%, 100% {transform: translate(100%);}
}
