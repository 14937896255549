.footer-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 4rem 2rem 6rem 2rem;
    background-color: #221f20;
    color: #fff;

    > p {
        &:first-of-type {
            display: flex;
            align-items: center;

            .link {
                margin-left: 2rem;
                font-size: 0.9rem;
                text-transform: uppercase;
                transition: opacity 0.5s;

                &:hover {
                    opacity: 0.7;
                }
            }
        }

        &:last-of-type {
            .secondary {
                width: 100%;
            }
        }
    }

    small {
        display: block;
        padding-top: 1rem;
        font-size: 0.65rem;
        line-height: 2;

        .link {
            color: inherit;
            font-size: 1em;
            font-weight: bold;
        }
    }

    .logo {
        display: inline-block;
        width: 10rem;
        margin-right: 2rem;
    }
}

@media screen and (max-width: 75rem) {
    .footer-wrapper {

        .logo {
            padding-bottom: 2rem;
        }

        > p {
            &:first-of-type {
                flex-direction: column;
                align-items: flex-start;

                .link {
                    margin-left: 0;
                    margin-bottom: 2rem;
                }
            }
        }
    }
}

@media screen and (max-width: 40rem) {
    .footer-wrapper {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 1rem;

        .filter {
            padding-bottom: 2rem;
        }

        > p {
            &:first-of-type {
                margin-bottom: 4rem;
            }
        }
    }
}
