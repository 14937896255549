.form-sale-wrapper {
    .react-datepicker {
        border: none;
        font-family: inherit;
    }

    .react-datepicker__header {
        background-color: #fff;
        border-radius: 0;
        border-bottom: 1px solid #cadceb;
    }

    .react-datepicker__current-month {
        text-transform: uppercase;
        font-weight: 400;
    }

    .react-datepicker__navigation--next {
        border-left-color: #2962b6;

        &:hover {
            border-left-color: #003e9b;
        }
    }

    .react-datepicker__navigation--previous {
        border-right-color: #2962b6;

        &:hover {
            border-right-color: #003e9b;
        }
    }

    .react-datepicker__day, .react-datepicker__time-name {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 3rem;
        line-height: 3;
        font-size: 0.9rem;
    }

    .react-datepicker__day-name {
        width: 2.5rem;
        line-height: 4;
        font-size: 0.9rem;
    }

    .react-datepicker__day--selected {
        background-color: #0055b8;
    }
}

@media screen and (max-width: 40rem) {
    .form-sale-wrapper {
        .react-datepicker__day, .react-datepicker__time-name {
            width: 2rem;
            height: 2rem;
            line-height: 2.4;
        }

        .react-datepicker__day-name {
            width: 2rem;
        }
    }
}

@media screen and (max-width: 35rem) {
    .form-sale-wrapper {
        .react-datepicker__day, .react-datepicker__time-name {
            width: 1.75rem;
            height: 1.75rem;
            line-height: 2.25;
        }

        .react-datepicker__day-name {
            width: 1.75rem;
        }
    }
}
