.authorize-admin-form {
    padding-right: 15%;
    min-height: 20rem;

    label {
        display: block;
        padding-bottom: 0.5rem;
    }

    .form-item {
        width: 49%;
        padding-bottom: 1rem;
    }

    .subForm-label {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 0.5rem;
        padding-top: 1rem;

        .hint {
            color: #888;
            font-size: 0.8rem;
        }
    }

    .form-action-wrapper {
        width: 100%;
        text-align: right;
    }
}

@media screen and (max-width: 75rem) {
    .authorize-admin-form {
        min-height: unset;
    }
}

@media screen and (max-width: 50rem) {
    .authorize-admin-form {
        padding-right: 0;

        .form-item {
            width: 100%;
        }
    }
}
