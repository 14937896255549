.card-form-wrapper {
    padding: 1rem 0 0 0;
    width: 100%;
    margin: 0 auto;

    .label-alternative-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;

        .label-alternative {
            color: #9b9b9b;
            margin: 0 1rem 0 1rem;
        }

        .line {
            height: 1px;
            width: 40px;
            background-color: #9b9b9b;
        }
    }

    .card-field {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
        height: 40px;
        background: white;
        font-size: 17px;
        color: #3a3a3a;
    }

    .row {
        display: -ms-flexbox; /* IE10 */
        display: flex;
        justify-content: space-between;

        .column {
            width: 100%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;

            &:first-child {
                padding-left: 0;
                padding-right: 0.5rem;
            }

            &:last-child {
                padding-left: 0.5rem;
                padding-right: 0;
            }
        }
    }

    input[type="text"],
    textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
        height: 40px;
        background: white;
        font-size: 17px;
        color: #3a3a3a;
    }

    select {
        width: 100%;
        padding: 11px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
        height: 40px;
        background-color: #ffffff;
        background-image: url("../../../../assets/images/icons/arrow_down.svg");
        background-size: 1.25rem;
        background-repeat: no-repeat;
        background-position: center right 1rem;
        font-size: 17px;
        color: #3a3a3a;
    }

    .message_container {
        border-radius: 5px;
        background: #ffffff;
        font-size: 13px;
        font-family: monospace;
        padding: 20px;
    }
    #loading {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        display: block;
        opacity: 0.7;
        background-color: #fff;
        z-index: 99;
        text-align: center;
    }
    #loading-image {
        position: absolute;
        z-index: 15;
        top: 50%;
        left: 50%;
        margin: -100px 0 0 -150px;
    }
    .spinner {
        position: fixed;
        top: 50%;
        left: 50%;
        margin-left: -50px; /* half width of the spinner gif */
        margin-top: -50px; /* half height of the spinner gif */
        text-align: center;
        z-index: 1234;
        overflow: auto;
        width: 100px; /* width of the spinner gif */
        height: 102px; /* height of the spinner gif +2px to fix IE8 issue */
    }
    .button_container {
        display: flex;
        justify-content: center;
    }
}
