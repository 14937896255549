body {
    color: #404040;
    font-weight: 300;
    font-family: "avenir", Helvetica, Arial, sans-serif;

}

.modal-active {
    overflow: hidden;
    height: 100vh;
}

h1 {
    position: relative;
    height: 1em;
    color: #0055b8;
    font-size: 5rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: -0.05em;

    &::before {
        position: absolute;
        top: -0.15em;
        text-transform: uppercase;
        font-size: 1.2em;
        white-space: nowrap;
        content: attr(data-title);
        opacity: 0.1;
    }
}

button,
a {
    display: inline-block;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;

    &:disabled {
        opacity: 0.2;

        &:hover {
            background-color: inherit;
            cursor: not-allowed;
        }
    }

    &.primary {
        height: 3rem;
        padding: 1rem 3rem;
        border-radius: 2rem;
        color: #fff;
        background-color: #0055b8;
        font-size: 1rem;
        line-height: 1.2;
        text-transform: uppercase;
        transition: background-color 0.3s;

        &:hover {
            background-color: #003e9b;
        }
    }

    &.secondary {
        position: relative;
        padding: 1rem 4rem 0.75rem 3rem;
        background-color: transparent;
        color: #fff;
        font-weight: 400;
        font-size: 0.9rem;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1;
        z-index: 0;

        &::before {
            position: absolute;
            left: -2px;
            right: -2px;
            bottom: -2px;
            top: -2px;
            border-radius: 2rem;
            background-image: linear-gradient(to right, #009be2, #00efac);
            content: '';
            z-index: -10;
        }

        &::after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            border-radius: 2rem;
            background-color: #221f20;
            background-image: url("./assets/images/icons/arrow_right_white.svg");
            background-position: right 2rem center;
            background-repeat: no-repeat;
            background-size: 1rem;
            content: '';
            z-index: -9;
            transition: opacity 0.5s;
        }

        &:disabled {
            opacity: 1;
        }

        &:hover {
            &::after {
                opacity: 0.5;
            }
        }

        &.active {
            padding: 1rem 3.5rem 0.75rem 3.5rem;

            &::after {
                background-image: none;
                opacity: 0.75;
            }
        }
    }

    &.cancel {
        background-color: #85898d;

        &:hover {
            background-color: adjust-color($color: #85898d, $lightness: -15%);
        }
    }

    &.link {
        display: inline-block;
        padding: 0;
        border-radius: 0;
        background-color: transparent;
        font-size: 1rem;
        font-weight: 400;
        letter-spacing: 0;
    }
}

main {
    width: 100%;
}

input {
    height: 3rem;
    width: 100%;
    padding: 0 1rem;
    border: 2px solid #eef3f9;
    border-radius: 2rem;
    background-color: #ffffff;
    font-family: inherit;
    font-weight: 300;
    font-size: 1rem;
    transition: box-shadow 0.5s, border-color 0.5s;

    &:focus {
        position: relative;
        border-color: #fff;
        box-shadow: 0 0 1rem 0 rgba(30, 90, 80, 0.15);
    }

    &:disabled {
        opacity: 0.5;
    }

    &.error {
        border-color: rgba(197, 114, 110, 0.2);
        color: #c5726e;
    }

    &.optional {
        // background-image: url("./assets/images/input-bg-optional.svg");
        background-size: 3.5rem;
        background-repeat: no-repeat;
        background-position: center right 1rem;
    }

    + .error-message {
        display: block;
        padding: 0.25rem 0 0.25rem 1rem;
        color: #c5726e;
        font-size: 0.9rem;
    }
}

select {
    height: 3rem;
    width: 100%;
    padding: 0 1rem;
    border: 2px solid #eef3f9;
    border-radius: 2rem;
    background-color: #ffffff;
    background-image: url("./assets/images/icons/arrow_down.svg");
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: center right 1rem;
    font-family: inherit;
    font-weight: 300;
    font-size: 1rem;

    &:focus {
        position: relative;
        border-color: #fff;
        box-shadow: 0 0 1rem 0 rgba(30, 90, 80, 0.15);
    }

    &.error {
        border-color: rgba(197, 114, 110, 0.2);
        color: #c5726e;
    }

    + .error-message {
        display: block;
        padding: 0.25rem 0 0.25rem 1rem;
        color: #c5726e;
        font-size: 0.9rem;
    }
}

.error {
    color: #c5726e;
}

td.error {
    text-align: right;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #9e9e9e;
}

::-moz-placeholder { /* Firefox 19+ */
    color: #9e9e9e;
    opacity: 1;
}

:-ms-input-placeholder { /* IE 10+ */
    color: #9e9e9e;
}

:-moz-placeholder { /* Firefox 18- */
    color: #9e9e9e;
}


div + .error-message {
    display: block;
    padding-top: 0.1rem;
    color: #c5726e;
    font-size: 0.75rem;
}

.page-content-wrapper {
    min-height: calc(100vh - 7rem);
    margin-top: 7rem;
    padding: 4rem 2rem;
}

.loading-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #082138;
}

.empty-state {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10vh 1rem;
    color: #888;
    line-height: 1.4;

    &::before {
        display: block;
        width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        background-image: url("./assets/images/icons/sad.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        content: "";
    }
}

.form-item {
    position: relative;
}

.checkbox-control {
    padding: 1rem 0;

    input[type='checkbox'] {
        display: none;
    }

    input[type='checkbox'] + label {
        position: relative;
        padding-left: 3rem;
        min-width: 1.5rem;
        min-height: 1.5rem;

        &:after,
        &:before {
            position: absolute;
            top: 0.1rem;
            left: 0;
            margin: auto 0;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 7px;
            box-sizing: border-box;
            content: '';
        }

        &:before {
            background-color: #ffffff;
            border: 1px solid #0055b8;
            opacity: 0.5;
        }

        &:after {
            background-color: #0055b8;
            background-image: url("./assets/images/icons/check_white.svg");
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center;
            opacity: 0;
            transition: opacity 0.5s;
        }
    }

    input[type='checkbox']:checked + label:after {
        opacity: 1;
    }

    label {
        display: block;
        color: #221f20;
    }
}

.error-wrapper {
    display: block;
    padding: 1rem;
    color: #c5726e;
    font-size: 1rem;
}

@media screen and (max-width: 75rem) {
    .page-content-wrapper {
        margin-top: 5rem;
        padding: 4rem 2rem 2rem;
        min-height: 75vh;
    }

    h1 {
        font-size: 4rem;
        height: unset;
        line-height: 1.2;
    }

    .empty-state {
        flex-direction: column;
        text-align: center;

        &::before {
            margin-right: 0;
            margin-bottom: 1rem;
        }
    }
}

@media screen and (max-width: 40rem) {
    .page-content-wrapper {
        margin-top: 4rem;
        padding: 2rem 1rem;
    }

    h1 {
        font-size: 8vw;

        &::before {
            display: none;
        }
    }

    input,
    select {
        height: 3.5rem;
        font-size: 1.1rem;
    }

    button,
    a {
        &.primary {
            height: 3.5rem;
            line-height: 1.8;
        }
    }
}


.truck-eligible-label {
    width: 100%;
    padding-left: 2rem;
    background-image: url('./assets/images/icons/truck.svg');
    background-size: 1.5rem;
    background-position: center left;
    background-repeat: no-repeat;
    color: #3377c6;
    font-size: 1rem;
    opacity: 1;
}

.cancel-action {
    color: #a82510;
}

a.back-action {
    padding-left: 2rem;
    margin-bottom: 2rem;
    color: #0055b8;
    background-image: url('./assets/images/icons/arrow_left.svg');
    background-size: 1.25rem;
    background-position: center left;
    background-repeat: no-repeat;

    &:visited {
        color: #0055b8;
    }

    &:disabled {
        opacity: 0.5;
    }
}


table {
    width: 100%;

    thead {

        tr {
            border-bottom: 1px solid #cadceb;
        }

        th {
            padding: 1rem 0.5rem 1rem 0;
            color: #888;
            font-size: 0.9rem;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.2rem;
            text-align: left;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #cadceb;
        }

        td {
            padding: 1rem 0.5rem 1rem 0;
            font-size: 1rem;
            font-weight: 300;
            vertical-align: middle;
        }
    }
}

@media screen and (max-width: 50rem) {
    table  {
        thead {
            display: none;
        }

        tbody {
            tr,
            td {
                display: block;
                width: 100%;
            }

            tr {
                position: relative;
                padding: 1.5rem 0;
            }

            td {
                padding: 0;

                + td {
                    padding-top: 1rem;
                }

                &[data-title] {
                    &::before {
                        padding-right: 1rem;
                        content: attr(data-title);
                    }
                }
            }
        }

        tfoot {
            tr {
                display: block;
                width: 100%;
                text-align: right;
            }
        }

        .mobile-hidden {
            display: none;
        }
    }
}
