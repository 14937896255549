.form-customer-wrapper {
    position: relative;
    padding: 2rem;
    height: 100%;
    background-image: linear-gradient(to right, #e5f5fc, #e5fdf7);
    transform: translateX(100%);
    transition: transform 0.5s;
    z-index: 10;

    &.visible {
        transform: translateX(0);
    }

    input {
        border-color: #e5f5fc;
    }

    .form-item {
        padding-bottom: 0.5rem;
    }

    .form-action {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .indent-buttom {
        padding-bottom: 1rem;
    }

    .two-columns {
        display: flex;
        justify-content: space-between;

        .form-item {
            width: 49%;
        }
    }

    .change-zip-action {
        width: 100%;
        padding-top: 0.5rem;
        padding-right: 1rem;
        color: #0055b8;
        font-size: 0.9rem;
        text-decoration: none;
        text-align: right;
    }

    .cancel-form-action {
        display: none;
    }
}

@media screen and (max-width: 75rem) {
    .form-customer-wrapper {
        position: fixed;
        top: 5rem;
        left: 0;
        right: 0;
        height: calc(100vh - 5rem);
        overflow-y: scroll;

        > * {
            max-width: 30rem;
        }

        .form-action {
            margin-top: 2rem;
        }

        .cancel-form-action {
            display: block;
            margin-left: 1rem;
            color: #888;
        }
    }
}

@media screen and (max-width: 40rem) {
    .form-customer-wrapper {
        top: 4rem;
        padding: 2rem 1rem;
        height: calc(100vh - 4rem);
    }
}
