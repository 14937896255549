.product {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 25%;
    padding: 1rem;
    padding-bottom: 5rem;
    margin-top: 2rem;
    cursor: pointer;

    &:nth-of-type(odd) {
        background-color: #eef8f6;

        h3::before {
            background-image: linear-gradient(to top, #eef8f6, rgba(239, 248, 246, 0.001));
        }
    }

    &:nth-of-type(even) {
        background-color: #e5f5fc;

        h3::before {
            background-image: linear-gradient(to top, #e5f5fc, rgba(229, 245, 252, 0.001));
        }
    }

    .product-image-wrapper {
        position: relative;
        width: 100%;
        height: 16rem;
        z-index: 0;

        &::before {
            position: absolute;
            left: 0;
            right: 0;
            width: 10rem;
            height: 10rem;
            margin: auto;
            border-radius: 50%;
            background-color: #fff;
            content: '';
            z-index: -10;
        }
    }

    .product-image {
        display: block;
        width: 100%;
        height: 15rem;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        z-index: 10;
    }

    h3 {
        width: 100%;
        color: #787a7c;
        background-color: inherit;
        font-size: 1.1rem;
        line-height: 1.4;
        text-align: center;
    }

    .product-details-action {
        padding: 1rem 0;
        color: #0055b8;
        font-size: 0.9rem;
    }

    .product-add-to-cart-action {
        position: absolute;
        bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        background-color: #006fba;
        color: #fff;

        .desc {
            margin-right: 0.5rem;
            font-size: 0.9rem;
        }

        .price {
            font-size: 1.1rem;
            font-weight: 500;
            color: #fff;
            line-height: 1;
        }
    }

    .truck-icon {
        margin-left: 0.5rem;
    }
}

@media screen and (min-width: 95rem) {
    .product {
        width: 20%;

        &:nth-of-type(5n - 4) {

            &::before {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 75rem) {
    .product {
        transition: box-shadow 0.5s;

        &::before {
            position: absolute;
            right: -2px;
            top: -2px;
            bottom: -2px;
            width: 1px;
            background-color: #fff;
            content: '';
        }

        &:nth-of-type(odd) {
            border: 2px solid #eef8f6;
        }

        &:nth-of-type(even) {
            border: 2px solid #e5f5fc;
        }

        &:nth-of-type(4n - 3) {

            &::before {
                display: none;
            }
        }

        &.truck-eligible .product-price::after {
            width: 1.75rem;
            height: 1.75rem;
            margin-left: 0.5rem;
            background-image: url('../../../../assets/images/icons/truck.svg');
            background-size: contain;
            opacity: 0.3;
            content: '';
        }

        h3 {
            position: relative;
            transition: transform 0.5s;

            &::before {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 100%;
                height: 4rem;
                content: '';
                opacity: 0;
                transition: opacity 0.5s;
            }
        }

        .product-price {
            position: absolute;
            bottom: 1.85rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #226595;
            font-weight: 500;
            font-size: 1.1rem;
            transition: opacity 0.5s;
        }

        .product-details-action {
            position: absolute;
            bottom: 4rem;
            opacity: 0;
            transition: opacity 0.5s;
        }

        .product-add-to-cart-action {
            margin-top: 1rem;
            opacity: 0;
            transition: opacity 0.5s, background-color 0.3s;
        }

        /* actions */

        &:hover {
            border-color: #fff;
            box-shadow: 0 0 3rem 0 rgba(30, 80, 115, 0.3);
            z-index: 10;

            &::before {
                opacity: 0;
            }

            h3 {
                transform: translateY(-2rem);

                &::before {
                    opacity: 1;
                }
            }

            .product-details-action {
                opacity: 1;
            }

            .product-price {
                opacity: 0;
            }

            .product-add-to-cart-action {
                opacity: 1;
            }
        }
    }
}

@media screen and (max-width: 75rem) {
    .product {
        width: 33%;
        border-left: 1px solid #fff;

        &.truck-eligible::after {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            width: 1.75rem;
            height: 1.75rem;
            background-image: url('../../../../assets/images/icons/truck.svg');
            background-size: contain;
            opacity: 0.3;
            content: '';
        }

        .product-price {
            display: none;
        }
    }
}

@media screen and (max-width: 55rem) {
    .product {
        width: 50%;
    }
}

@media screen and (max-width: 35rem) {
    .product {
        width: 100%;
        margin-top: 0;
        border-top: 1px solid #fff;
    }
}
