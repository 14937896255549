.page-content-wrapper.admin-content {
    margin-top: 0;
    padding-top: 6rem;

    .box {
        padding: 2rem;
        border: 1px solid #ebebeb;
        border-radius: 0.5rem;
        position: relative;

        > label {
            position: absolute;
            left: 15px;
            top: -8px;
            background-color: white;
        }

        .loader-inline-wrapper {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.admin-page {
    .header {
        position: fixed;
        height: 4rem;
        width: 100%;
        border-bottom: 1px solid #ebebeb;
        box-shadow: 0px 5px 5px 0px #e7e7e7;
        display: flex;
        align-items: center;
        padding: 1rem;
        justify-content: flex-end;
    }
}

.login-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .login-form {
        .form-group {
            input {
                margin-top: 1rem;
            }
        }

        .form-actions {
            margin-top: 2rem;
            justify-content: flex-end;
            display: flex;
        }
    }
}

.emails-queue-wrapper {
    display: flex;
    width: 40rem;

    button {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .info {
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 0.5rem;
            }
        }

        .actions {
            button:not(:last-child) {
                margin-right: 1rem;
            }
        }

        .test-email-form {
            padding-top: 1rem;
            display: flex;

            input {
                margin-right: 1rem;
            }
        }
    }
}
