.product-list-wrapper {
    display: flex;
    flex-wrap: wrap;

    .hint {
        display: flex;
        align-items: center;
        width: 100%;
        padding-top: 1rem;
        color: #3377c6;
        font-weight: 500;

        &::before {
            width: 1.75rem;
            height: 1.75rem;
            margin-right: 0.5rem;
            background-image: url('../../../../assets/images/icons/truck.svg');
            background-size: contain;
            opacity: 1;
            content: '';
        }
    }
}

@media screen and (max-width: 40rem) {
    .product-list-wrapper {
        .hint {
            padding-bottom: 1rem;
        }
    }
}
