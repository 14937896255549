.submit-order-wrapper {
    margin-top: 2rem;
    .loader-wrapper {
        position: relative;
        margin-bottom: 2rem;
        display: none;

        .message-loading,
        .message-failed,
        .message-success {
            position: absolute;
            font-size: 0.9rem;
            line-height: 1.4;
        }

        &.loading {
            display: block;
            .message-loading {
                padding-top: 2.5rem;
                color: #147fb0;

                &::before,
                &::after {
                    position: absolute;
                    left: 0;
                    top: 1rem;
                    height: 0.5rem;
                    border-radius: 1rem;
                    content: '';
                }

                &::before {
                    width: 10rem;
                    background-image: linear-gradient(to right, #e5f5fc, #e5fdf7);
                }

                &::after {
                    animation: loading 1s alternate infinite linear;
                }
            }

            .message-failed,
            .message-success,
            .message-submit-loading {
                opacity: 0;
            }
        }

        &.failed {
            display: block;
            .message-loading,
            .message-success,
            .message-submit-loading {
                opacity: 0;
            }

            .message-failed {
                margin-top: 2rem;
                padding: 1rem 0 1rem 4rem;
                background-image: url("../../../../assets/images/icons/sad.svg");
                background-repeat: no-repeat;
                background-size: 3rem;
                background-position: center left;
                color: #888;
            }
        }

        &.success {
            display: block;
            .message-loading,
            .message-failed,
            .message-submit-loading {
                opacity: 0;
            }

            .message-success {
                padding: 1rem 0 1rem 4rem;
                background-image: url("../../../../assets/images/icons/happy.svg");
                background-repeat: no-repeat;
                background-size: 3rem;
                background-position: center left;
                color: #0055b8;
                font-size: 1.4rem;
            }
        }
    }
}

@keyframes loading {
    0% {
        left: 0;
        width: 1rem;
        background-color: #009be2;
    }

    50% {
        left: 0;
        width: 5rem;
    }

    100% {
        left: 9rem;
        width: 1rem;
        background-color: #00efac;
    }
}

@media screen and (max-width: 50rem) {
    .submit-order-wrapper {
        .cancel-actions-wrapper {
            left: 2rem;
            right: 2rem;
            width: calc(100% - 4rem);
            text-align: center;
        }
    }
}
