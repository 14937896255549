.card-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #272729;
    padding-bottom: 2.5rem;

    .row-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
    }

    .column {
        width: 100%;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .column:first-child {
        padding-left: 0;
        padding-right: 0.5rem;
    }

    .column:last-child {
        padding-left: 0.5rem;
        padding-right: 0;
    }

    .card_field {
        background-color: rgba(163, 163, 163, 0.4);
        color: white;
        border-color: rgba(163, 163, 163, 0.4);
        border-radius: 3px;
        border-width: 1px;
        height: 45px;

        &.error {
            border-color: #ff0000;
        }
    }

    input[type=text],
    select,
    textarea {
        background-color: rgba(163, 163, 163, 0.4);
        border-color: rgba(163, 163, 163, 0.4);
        color: white;
        border-radius: 3px;
        border-width: 1px;
        height: 45px;
        outline: none;
    }

    select {
        background-image: url('../../../../assets/images/icons/arrow_down_white.svg');
        background-size: 1.25rem;
        background-repeat: no-repeat;
        background-position: center right 0.5rem;
    }

    ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #e5e5e5;
    }

    form {
        margin-block-end: 0;
    }

    .payment-button {
        width: 100%;
        color: white;
        height: 55px;
        background-color: #1e9a8b;
        outline: none;

        position: fixed;
        bottom: 0;

        &:hover {
            background-color: #1e9a8b;
        }

        &:disabled {
            background-color: #909191;
        }
    }

    .no-top-margin {
        margin-top: 0;
    }
}
