.page-new-order {

    .page-content-wrapper {
        display: flex;
        padding: 0;

        h2 {
            padding-bottom: 1.5rem;
            font-size: 1.5rem;
            font-weight: bold;
        }

        main {
            width: 100%;
            padding: 4rem 2rem;
        }

        aside {
            position: relative;
            width: 35%;
            background-image: linear-gradient(to right, #e5f5fc, #e5fdf7);
            overflow: hidden;
        }
    }

    .message {
        padding: 2rem 0;
        line-height: 1.4;
        font-size: 1rem;
    }

    .new-order-actions-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 5rem;
        padding: 2rem 0;
    }
}

@media screen and (max-width: 75rem) {
    .page-new-order {

        .page-content-wrapper {
            flex-direction: column;
            position: relative;
            padding-bottom: 8rem;

            main, aside {
                width: 100%;
            }
        }

        .new-order-actions-wrapper {
            position: absolute;
            bottom: 0;
            right: 2rem;
            margin-top: 0;
            border-top: none;
        }
    }
}

@media screen and (max-width: 50rem) {
    .page-new-order {

        .new-order-actions-wrapper {
            right: 0;
            left: 0;
            justify-content: center;
        }
    }
}
