.table-cart-wrapper {
    table {
        tbody {
            td {
                font-weight: 400;
                font-size: 1.1rem;
            }

            .title {
                max-width: 30vw;
                color: #000;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.4;
            }

            .truck-eligible-label {
                margin-top: 1rem;
            }
        }

        tfoot {
            td {
                padding: 2rem 0;
                background-color: #e5fdf7;
                font-size: 1.2rem;
                font-weight: 500;

                &:nth-of-type(1) {
                    background-image: linear-gradient(to right, #e5f5fc, #e5fdf7);
                }
            }
        }
    }

    .product-remove-action {
        width: 2rem;
        height: 2rem;
        padding: 0;
        background-color: transparent;
        background-image: url('../../../../assets/images/icons/cross_red.svg');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }
    }

    .quantity-selection-wrapper {
        display: flex;

        button {
            width: 3.5rem;
            padding-left: 1rem;
            padding-right: 1rem;
            font-size: 1.5rem;
            line-height: 0.1;
            font-weight: 500;
        }

        input {
            width: 5rem;
            margin: 0 0.5rem;
            text-align: center;
        }
    }

    .no-available {
        display: block;
        padding: 0.25rem 0 0.25rem 1rem;
        color: #c5726e;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 50rem) {
    .table-cart-wrapper {
        table {
            tbody {
                .title {
                    max-width: unset;
                    padding-right: 3rem;
                }
            }

            tfoot {
                tr {
                    background-image: linear-gradient(to right, #e5f5fc, #e5fdf7);
                }

                td {
                    display: inline-block;
                    padding-right: 1rem;
                    background-color: transparent;

                    &:nth-of-type(1) {
                        background-image: none;
                    }
                }
            }
        }

        .product-remove-action {
            position: absolute;
            right: 0;
            top: 1rem;
            z-index: 10;
        }
    }
}
