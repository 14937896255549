.checkout-products-wrapper {
    table {
        thead {
            th {
                padding-right: 2rem;

                &:not(:first-of-type) {
                    text-align: right;
                }
            }
        }
        tbody {
            td {
                padding-right: 2rem;
                vertical-align: top;

                &:not(:first-of-type) {
                    text-align: right;
                }
            }

            del {
                display: block;
                padding-bottom: 2rem;
                opacity: 0.5;
            }

            .discounted td {
                padding-bottom: 2rem;
            }

            .title {
                color: #000;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.4;
            }
        }

        tfoot {
            td {
                padding-right: 2rem;
                padding-top: 1rem;
                font-size: 1.1rem;

                &:not(:first-of-type) {
                    text-align: right;
                }
            }

            .grand-total td {
                font-weight: 500;
                font-size: 1.5rem;
            }
        }
    }

    .form-discount-wrapper {
        display: flex;
        margin-top: 1rem;

        input {
            width: 5rem;
            margin-right: 0.5rem;
            text-align: center;
        }

        select {
            width: 18rem;
        }

        .remove-discount-action {
            display: none;
        }
    }

    .form-promocode-wrapper {
        position: absolute;
        display: flex;
        width: 25.0rem;

        input {
            padding-right: 3rem;
        }

        .apply-promocode-action {
            position: absolute;
            right: 0;
            top: 0;
            width: 4rem;
            height: 3rem;
            background-color: transparent;
            background-image: url('../../../../assets/images/icons/arrow_right.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1.25rem;
            z-index: 0;

            &:disabled {
                opacity: 0;
                z-index: -10;
            }
        }

        .remove-promocode-action {
            display: none;
        }
    }

    .add-discount-action {
        margin-top: 0.5rem;
        color: #0055b8;
        font-size: 0.9rem;
    }

    .remove-discount-action,
    .remove-promocode-action {
        position: absolute;
        width: 1rem;
        height: 1rem;
        padding: 0;
        margin-left: 1rem;
        background-color: transparent;
        background-image: url('../../../../assets/images/icons/cross_red.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5;
        vertical-align: top;

        &:hover {
            opacity: 1;
        }
    }

    .add-promocode-action {
        padding-top: 0.25rem;
        padding-left: 1.75rem;
        color: #0055b8;
        font-size: 1rem;
        background-image: url('../../../../assets/images/icons/star.svg');
        background-size: 1.25rem;
        background-repeat: no-repeat;
        background-position: center left;
    }
}

@media screen and (max-width: 50rem) {
    .checkout-products-wrapper {
        table {
            tbody {
                td {
                    padding-right: 0;

                    &:not(:first-of-type) {
                        text-align: left;
                    }
                }

                .discounted td {
                    padding-bottom: 0;
                }

                del {
                    display: inline-block;
                    padding-bottom: 0;
                    padding-right: 1rem;
                }
            }

            tfoot {

                td {
                    display: inline-block;
                    padding-right: 1rem;
                }

                .promocode-column {
                    display: block;
                    width: 100%;
                    margin-top: 1.5rem;
                    padding: 0;
                    text-align: left;
                }
            }
        }

        .form-discount-wrapper {
            position: relative;
            flex-direction: column;
            padding: 1rem;
            border-radius: 1rem;
            background-color: rgba(15, 78, 179, 0.06);

            select {
                width: 100%;
            }

            input {
                width: 60%;
                min-width: 7rem;
                margin-right: 0;
                margin-bottom: 0.5rem;
            }

            .remove-discount-action {
                top: 0;
                right: 1rem;
                display: block;
                margin-right: 0;
            }
        }

        .form-promocode-wrapper {
            position: relative;
            width: 100%;
            padding: 1rem;
            border-radius: 1rem;
            background-color: rgba(15, 78, 179, 0.06);

            .remove-promocode-action {
                display: block;
                right: 1rem;
                top: 1rem;
                margin: auto;
            }

            input {
                width: 16rem;
            }

            .apply-promocode-action {
                right: unset;
                left: 11rem;
                top: 0;
            }
        }

        .remove-discount-action,
        .remove-promocode-action {
            display: none;
            width: 1.25rem;
            height: 1.25rem;
        }
    }
}

@media screen and (max-width: 40rem) {
    .checkout-products-wrapper {
        .form-promocode-wrapper {
            .apply-promocode-action {
                height: 3.5rem;
            }
        }
    }
}
