header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    color: #fff;
    background-color: #221f20;
    z-index: 110;

    a.link,
    button.link {
        color: #fff;
        font-size: 0.9rem;
        text-transform: uppercase;

        &:visited {
            color: inherit;
        }

        &.logout {
            padding-left: 2rem;
            margin-top: 0.5rem;
        }
    }

    .logos-wrapper {
        display: flex;

        .divider {
            width: 2px;
            background-color: rgb(170, 170, 170);
            margin-left: 1rem;
            margin-right: 1rem;
        }
    }

    .logo-one-step {
        display: block;
        width: 14rem;
        height: 3.5rem;
        background-image: url("https://mastec-assets.s3.us-west-2.amazonaws.com/logo-one-step-white.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .logo-third-party {
        display: block;
        width: 12rem;
        height: 3.5rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }

    .main-nav-wrapper,
    .sub-nav-wrapper {
        display: flex;
        align-items: center;
    }

    .sub-nav-wrapper {
        justify-content: flex-end;
    }

    .mobile-menu-wrapper {
        > * {
            margin-left: 2rem;
        }

        .link.products {
            margin-top: 0.5rem;
        }
    }

    a.cart {
        margin-left: 2rem;

        .quantity {
            position: absolute;
            left: 1rem;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 1.5rem;
            min-width: 1.5rem;
            margin: auto;
            border-radius: 2rem;
            padding: 0 0.4rem;
            background-color: #204758;
            color: #fff;
            font-size: 0.7rem;
            font-weight: 500;
            line-height: 1;
            transition: background-color 0.5s;
        }

        .icon {
            display: none;
            width: 1.5rem;
            height: 1.5rem;
            background-image: url('../../assets/images/icons/cart.svg');
            background-size: 100%;
            background-repeat: no-repeat;
        }

        &:hover,
        &.active {
            .quantity {
                background-color: #1c97c8;
            }
        }
    }

    button.login {
        margin-left: 2rem;
    }

    button.mobile-menu-icon {
        display: none;
        margin-left: 2rem;
        width: 2rem;
        height: 2rem;
        background-image: url('../../assets/images/icons/menu.svg');
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media screen and (max-width: 75rem) {
    header {
        height: 5rem;

        .logo-one-step {
            width: 10rem;
        }

        .logo-third-party {
            width: 8rem;
        }

        a.cart {
            padding: 0.75rem 3rem 0.5rem 1.5rem;

            &::after {
                background-position: right 1.5rem center;
            }

            .quantity {
                top: -0.75rem;
                left: 1rem;
                height: 1.25rem;
                min-width: 1.25rem;
                background-color: #0055b8;
            }

            .text {
                display: none;
            }

            .icon {
                display: inline-block;
            }

            &.active {
                padding: 0.75rem 1.5rem 0.5rem 1.5rem;
            }
        }

        .mobile-menu-wrapper {
            position: fixed;
            top: 5rem;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            padding: 2rem;
            background-color: #221f20;
            transform: translateX(100%);
            z-index: 300;

            &.visible {
                transform: translateX(0);

                ~ .mobile-menu-icon {
                    background-image: url('../../assets/images/icons/cross_white.svg');
                }
            }

            > * {
                margin-left: 0;
            }
        }

        button.mobile-menu-icon {
            display: inline-block;
        }

        &.zip-modal-visible {
            .cart,
            .mobile-menu-wrapper,
            button.mobile-menu-icon {
                display: none;
            }
        }

        &.product-page {
            button.mobile-menu-icon {
                background-image: url('../../assets/images/icons/filter.svg');
            }
        }
    }
}

@media screen and (max-width: 40rem) {
    header {
        height: 4rem;
        padding-left: 1rem;
        padding-right: 1rem;

        .logo-one-step {
            width: 9rem;
        }

        .logo-third-party {
            width: 7rem;
        }

        .mobile-menu-wrapper {
            top: 4rem;
            padding-left: 1rem;
        }

        a.cart {
            padding: 0 0.5rem;

            &.active {
                padding: 0 0.5rem;
            }

            &::before,
            &::after {
                display: none;
            }

            .quantity {
                left: 0;
            }
        }

        button.mobile-menu-icon {
            margin-left: 1rem;
        }
    }
}
