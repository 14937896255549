.filter {
    h3 {
        display: none;
        padding-bottom: 1rem;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        opacity: 0.5;
    }

    .filter-action {
        margin-left: 2rem;
        color: #fff;
        font-size: 0.9rem;
        text-transform: uppercase;
        margin-top: 0.5rem;

        &.active {
            color: #a2d6d5;
        }
    }
}

@media screen and (max-width: 75rem) {
    .filter {
        h3 {
            display: block;
        }

        .filter-action {
            width: 100%;
            margin-left: 0;
            margin-bottom: 2rem;
            text-align: left;
            margin-top: 0;
        }
    }
}

@media screen and (max-width: 50rem) {
    .filter {
        .filter-action {
            font-size: 1rem;
        }
    }
}
