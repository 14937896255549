.order-products-wrapper {
    table {
        thead {
            th {
                padding-right: 2rem;

                &:not(:first-of-type) {
                    text-align: right;
                }
            }
        }
        tbody {
            td {
                padding-right: 2rem;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
                vertical-align: top;

                &:not(:first-of-type) {
                    text-align: right;
                }
            }

            del {
                display: block;
                padding-bottom: 1rem;
                opacity: 0.5;
            }

            .title {
                color: #000;
                font-size: 1.1rem;
                font-weight: 500;
                line-height: 1.4;
            }
        }

        tfoot {
            td {
                padding-right: 2rem;
                padding-top: 1rem;
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 50rem) {
    .order-products-wrapper {
        table {
            tbody {
                td {
                    padding-right: 0;
                    padding-bottom: 0;

                    &:not(:first-of-type) {
                        text-align: left;
                    }
                }

                .discounted td {
                    padding-bottom: 0;
                }

                del {
                    display: inline-block;
                    padding-bottom: 0;
                    padding-right: 1rem;
                }
            }

            tfoot {

                td {
                    display: inline-block;
                    padding-right: 1rem;
                }

                .promocode-column {
                    display: block;
                    width: 100%;
                    margin-top: 1.5rem;
                    padding: 0;
                    text-align: left;
                }
            }
        }
    }
}
