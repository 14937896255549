.modal-product-wrapper {
    position: relative;

    h1 {
        position: relative;
        z-index: 10;
    }

    h2 {
        padding-bottom: 1rem;
        color: #888;
        font-size: 1rem;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.2rem;
    }

    h3 {
        padding-bottom: 0.5rem;
        color: #000;
        font-size: 1.1rem;
        font-weight: 500;

        + h3 {
            padding-top: 1rem;
        }
    }

    .product-details-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 30rem;


        .details-section {
            width: 48%;

            p {
                color: #000;
                line-height: 1.4;

                + h3 {
                    padding-top: 1.5rem;
                }
            }
        }

        .disclaimer {
            display: block;
            padding-top: 1rem;
            color: #888;
            font-size: 0.8rem;
            line-height: 1.6;
        }

        .truck-eligible-label {
            margin-bottom: 2rem;
            padding-bottom: 0;
        }
    }

    .product-add-to-cart-action {
        position: absolute;
        top: 22rem;
        right: -4rem;
        width: 12rem;
        height: 12rem;
        padding: 1rem;
        border-radius: 50%;
        color: #fff;
        background-color: #0055b8;
        font-size: 1.3rem;
        font-weight: 500;
        line-height: 1;
        z-index: 11;
        transition: box-shadow 0.5s;

        .price {
            display: block;
            padding-bottom: 0.5rem;
            font-size: 1.7em;
        }

        &:hover {
            box-shadow: 0 0 3rem 0 rgba(30, 80, 115, 0.3);
            background-color: #003e9b;
        }
    }

    .slider {
        position: absolute;
        right: -8rem;
        top: -8rem;
        width: 35rem;
        height: 35rem;
        padding-top: 4rem;
        padding-right: 2rem;
        background-color: #eef8f6;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 9;

        .images-wrapper {
            position: relative;
            width: 60%;
            height: 60%;

            span {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                opacity: 0;
                transition: opacity 1s;

                &.active {
                    opacity: 1;
                }
            }
        }

        .dot-nav-wrapper {
            padding-top: 2rem;

            .dot-action {
                padding: 0;
                width: 1.5rem;
                height: 1.5rem;
                border-radius: 50%;
                border: 0.45rem solid #eef8f6;
                background-color: #006fba;
                opacity: 0.2;

                &.active {
                    opacity: 1;
                }
            }
        }

        .arrow-action {
            position: absolute;
            top: 45%;
            width: 2rem;
            height: 2rem;
            padding: 0;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 70%;


            &.show-prev {
                left: 1rem;
                background-image: url('../../../../assets/images/icons/arrow_left.svg');
            }

            &.show-next {
                right: 6rem;
                background-image: url('../../../../assets/images/icons/arrow_right.svg');
            }
        }
    }
}

@media screen and (max-width: 75rem) {
    .modal-product-wrapper {
        h1 {
            padding-right: 2rem;
        }

        .product-details-wrapper {
            padding-right: 0;
            margin-top: -5rem;
        }

        .product-add-to-cart-action {
            position: relative;
            display: block;
            top: -10rem;
            right: -10rem;
            margin: auto;
        }

        .slider {
            position: relative;
            right: 0;
            top: 0;
            width: 90vw;
            height: 90vw;
            max-width: 35rem;
            max-height: 35rem;
            margin: auto;
            padding: 2rem;

            .arrow-action.show-next {
                right: 1rem;
            }
        }
    }
}

@media screen and (max-width: 50rem) {
    .modal-product-wrapper {
        .product-details-wrapper {

            .details-section {
                width: 100%;
                padding-bottom: 3rem;
            }
        }
    }
}

@media screen and (max-width: 40rem) {
    .modal-product-wrapper {
        .product-details-wrapper {
            margin-top: -15vw;
        }

        .slider {
            width: 80vw;
            height: 80vw;

            .images-wrapper {
                width: 100%;
                height: 100%;
                transform: translateY(2rem);
            }

            .dot-nav-wrapper {
                position: absolute;
                top: 100%;

                .dot-action {
                    border-color: #e5f0f8;
                }
            }

            .arrow-action {
                &.show-prev {
                    left: -2rem;
                }

                &.show-next {
                    right: -2rem;
                }
            }
        }

        .product-add-to-cart-action {
            width: 35vw;
            height: 35vw;
            right: -25vw;
            top: -25vw;
            font-size: 4vw;
        }
    }
}
