.form-paypal-invoice-wrapper {
    display: flex;
    flex-wrap: wrap;

    .form-item {
        width: 40%;
        margin-right: 0.5rem;
    }
}

@media screen and (max-width: 50rem) {
    .form-paypal-invoice-wrapper {
        .form-item {
            width: 100%;
            margin-bottom: 1rem;
        }

        .form-action-wrapper {
            width: 100%;
            text-align: right;
        }
    }
}
