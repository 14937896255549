.page-checkout {
    .page-content-wrapper {
        display: flex;
        min-height: 50rem;
        padding: 0;

        h2 {
            padding-bottom: 1rem;
            font-size: 1.5rem;
            font-weight: bold;
        }

        main {
            width: 65%;
            padding: 4rem 2rem;
        }

        aside {
            position: relative;
            width: 35%;
            background-image: linear-gradient(to right, #e5f5fc, #e5fdf7);
            overflow: hidden;
        }

        .navigation-wrapper {
            display: flex;
            justify-content: space-between;

            .standard-checkout-action {
                color: #0055b8;

                &:visited {
                    color: #0055b8;
                }

                &:disabled {
                    opacity: 0.5;
                }
            }
        }
    }

    .checkout-actions-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 5rem;
        padding: 2rem 0;
        border-top: 1px solid #cadceb;
        align-items: flex-start;

        .payment-wrapper {
            width: 80%;

            .payment-button-wrapper {
                display: flex;
                justify-content: flex-end;
            }
        }

        .pp {
            color: #221f20;
            background-color: #ffce42;
            // margin-left: 1rem;
        }

        .btn-cc {
            padding-left: 1rem;
            width: 20rem;
            overflow: hidden;

            &>div {
                margin-top: -3.5rem;
            }

            &.without-margin {
                &>div {
                    margin-top: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 75rem) {
    .page-checkout {
        .page-content-wrapper {
            position: relative;
            padding-bottom: 10rem;
            flex-direction: column;

            main {
                width: 100%;
                padding: 2rem;
            }

            aside {
                position: static;
                width: 100%;
            }
        }

        &.form-customer-visible {
            height: 100vh;
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 50rem) {
    .page-checkout {
        .page-content-wrapper {
            padding-bottom: 15rem;
        }
    }
}

@media screen and (max-width: 40rem) {
    .page-checkout {
        .page-content-wrapper {
            main {
                padding: 2rem 1rem;
            }
        }
    }
}
