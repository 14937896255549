.page-new-order .form-sale-wrapper {
    .order-date,
    .installation-date,
    .installation-time {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem;
    }
}
